<template id='Screen' >

  <div class="Screen">

    <div class="screensaver"  :style="{height:this.screenHeight + 'px', width: this.screenWidth + 'px' }" v-if="this.$store.state.main.screensaver" >
<!--       <video ref="myVideoTag" class="video"  controls :width="this.screenWidth" :height="this.screenHeight" preload="none" muted="" autoplay >
        <video  class="video"  controls="controls" :width="this.screenWidth" :height="this.screenHeight" >
        <source  :src="require('@/assets/screen.mp4')" type="video/mp4">
      </video> -->
      <div class="banner" >
      <p class="banner_tittle">Йога без ошибок</p>
      <p class="banner_text">Осознаная практика</p>
      </div>
    </div>

      <swiper :modules="modules" :pagination="{ type: 'fraction' }" :navigation='true' :slides-per-view="1" @swiper="onSwiper" @slideChange="onSlideChange" :activeIndex="2">

        <swiper-slide  v-for="(info, index) of GET_API_DATA" :key="index">
          <div class="main_screen" style="" :style="
          {height:this.screenHeight +'px'}
          ">
          <h2 v-if="!this.$store.state.main.internet" class="box_internet" >Нет подключения к интернету</h2>
          <div v-if="this.$store.state.main.internet" class="boxes" :style="{background:'url(' + this.$store.state.option.media + info.image + ') center center / cover no-repeat'}">
            <div class="box">
              <h1 class="tittle">{{info.quote}}</h1>
              <button @click="this.$router.push({path:'/lesson' , query:{id:info.id}})" class="btn_video"></button>
            </div>
          </div>
          </div>
        </swiper-slide>


      </swiper>
  </div>



 

</template>


<script>
import SwiperClass, { Pagination } from 'swiper'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

export default {
  name:'Screen',
  components: {
    Swiper,
    SwiperSlide
  },
  data(){
  	return{
      modules: [Pagination, Navigation],
      screenHeight:window.screen.height,
      screenWidth:window.screen.width,
      swiper:null,
  	}
  },
  mounted(){
    this.stopLoad()
    this.GET_DATA_API()
    this.savePosition()
  //   screen.addEventListener("orientationchange", function () {
  // console.log("The orientation of the screen is: " + screen.orientation);
// });
    // document.body.style.transform = 'rotate(90deg)';
  },
  created(){  	
  },
  methods:{
  ...mapMutations({
	}),
	...mapActions({
    GET_DATA_API:'GET_DATA_API',
	}),
  onSwiper(swiper){
    console.log(swiper)
    this.swiper = swiper
  },
  onSlideChange(){
    this.$router.push({query:{slide:this.swiper.activeIndex}})
    // console.log(this.swiper.activeIndex)
  },
  savePosition(){
    this.swiper.slideTo(this.$route.query.slide);
    },
  stopLoad(){
    if (this.$store.state.main.screensaver == true){
      setTimeout(()=> {
      this.$store.state.main.screensaver = false
    }, 4000)
    } else {

    }
    
  }
  },
  computed:{
  ...mapGetters([
    'GET_API_DATA',
	]),
  },
}
</script>

<style scoped>
.box_internet {
    position: absolute;
    text-align: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 40%;
    border-radius: 20px;
    background: rgba(0,0,0, .5);
    padding-bottom: 5%;
    width: 85%;
    color:#fff;
    padding:5%;
}
.boxes{
    height: 80%;
    width: 95%;
    margin: auto;
    border-radius: 30px;
    top: -125px;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
}
@keyframes show {
from {opacity:0;}

to {opacity:1;}
}
@keyframes run {
from {background-position-x: 0px;}

to {background-position-x: -204px;}
}
.screensaver{
    background-image: url('@/assets/background.jpg');
    background-position-x: -204px;
    background-position-y: -130px;
    animation: run  2s ease-in-out;
}
@import url('https://fonts.googleapis.com/css2?family=El+Messiri&display=swap');
.banner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 150px;
    animation: show  3s ease-in-out;
}
.banner_tittle{
    color: #fff;
    text-align: center;
    font-size: 34px;
    font-family: 'El Messiri';
    margin-top: 0px;
    margin-bottom: 10px;
    text-transform:uppercase;
}
.banner_text{
    color: #fff;
    text-align: center;
    background: #f29f34;
    padding: 0px;
    width: 80%;
    font-family: El Messiri;
    margin: 0 auto;
    border-radius: 10px;
    font-size: 24px;
    letter-spacing: 4px;

}
.video {


}
video::-webkit-media-controls {
  display:none !important;
}
.tittle {
  color:#fff;
  font-size:36px;
  font-weight:normal;
}
.btn_video{
  outline: none;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: none;
  padding: 0px;
  position:relative;
}
.btn_video:before{
  content:'';
  position:absolute;
  -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  background:#000;
  width:12px;
  height:10px;
  border-radius:0px;
  top: 0;
  left: 0;
  bottom: 0;
  right: -2px;
  margin: auto;
  transform: rotate(90deg);
}
.box {
    position: absolute;
    text-align: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 30%;
    border-radius: 20px;
    background: rgba(0,0,0, .5);
    padding-bottom: 5%;
    width: 95%;

}
.Screen{
  height:100%;
  overflow:hidden;
}
.container-fluid{
  padding-left:0px;
  padding-right:0px;
}
.main_screen{
  width:100%;
  background-image: linear-gradient(rgba(61, 99, 157, 0.7) 0%, #343D63 100%);
} 
</style>

