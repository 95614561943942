import { createStore } from 'vuex'
import option from './option'
import lesson from './lessonScreen/main.js'
import main from './mainScreen/main.js'

export default createStore({
  state: {

  },
  modules: {
    option,
    lesson,
    main,
  },
  mutations: {

  },
  actions: {
  },
  getters: {

  },
})
