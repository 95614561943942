import { createRouter, createWebHistory } from 'vue-router'
import mainScreen from '../views/mainScreen/mainScreen.vue'
import lessonScreen from '../views/lessonScreen/lessonScreen.vue'

const routes = [
  {
    path: '/',
    name: 'mainScreen',
    component: mainScreen,
    query:{slide:''}
  },
  {
    path: '/lesson',
    name: 'lessonScreen',
    component: lessonScreen,
    query:{id:''}
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
