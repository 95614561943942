import VueAxios from 'vue-axios'
import axios from 'axios'

export default {
	state:{
		api:'https://zitatnik.ru/api/',
		media:''
	},
	mutations:{
	},
	actions:{
	},
	getters:{
	}
}