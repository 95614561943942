import VueAxios from 'vue-axios'
import axios from 'axios'
import option from '../option'

export default {
	 modules: {
    	option,
  	},
	state:{
		data:[],
		screensaver:true,
		internet:true,	
	},
	mutations:{
	SET_DATA(state, info){
      console.log(info)
      state.data = info
    },
	},
	actions:{
	GET_DATA_API({commit, state}) {
      axios({
        method:'GET',
        url:`${option.state.api}globalApi/?category=10`
      }).then(info => {commit('SET_DATA', info.data), state.internet = true}).catch(function(error){
            console.log(error)
            state.internet = false        
      })       
    },
	},
	getters:{
	GET_API_DATA(state){
      return state.data
    },
	}
}