import VueAxios from 'vue-axios'
import axios from 'axios'
import option from '../option'

export default {
	modules: {
    	option,
  	},
	state:{
		background:[],
	},
	mutations:{
	SET_BACKGROUND_DATA(state, info){
      console.log(info)
      state.background = info
    },	
	},
	actions:{
    GET_BACKGROUND_API({commit, state}) {
      axios({
        method:'GET',
        url:`${option.state.api}globalApi/?category=11`
      }).then(info => {commit('SET_BACKGROUND_DATA', info.data)})        
    },
	},
	getters:{
    GET_BACKGROUND_DATA(state){
      return state.background
    },
	}
}