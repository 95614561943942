

<!-- this.background[0].image -->
<template>
<!--   <div class="box" :style="
          {background:'url(' + this.$store.state.option.media +  + ') center center / cover no-repeat', height:this.screenHeight +'px'}
          "> -->
          <div v-if="badRenderComponent" class="box" :style="
          {height:this.screenHeight +'px'}">
    <div class="back" @click="this.$router.go(-1)">
      <div class="arrow"></div>
      <p class="tittle_menu">Йога без ошибок</p>
      
    </div>      
   <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="marketing_box" v-for="(el, index) of this.getLesson" :key="index">
          <!-- <p class="text">Реклама</p> -->
          <p class="title">{{el.marketing.marketing_description || ''}}</p>
          <a class="link" :href="el.marketing.marketing_link || ''" target="_blank">Подробнее</a>
        </div>
        <div class="video" v-for="(el, index) of this.getLesson" :key="index">
          <div class="" v-html="el.video_link"></div>
          <div class="donate">
          <button class="btn_dn" @click="open = !open">Поддержать автора</button>
          <div v-if="!open" class="donate_box">
            <form method="POST" action="https://yoomoney.ru/quickpay/confirm.xml"><input type="hidden" name="receiver" value="4100117604475598"><input type="hidden" name="formcomment" value="Проект «Железный человек»: реактор холодного ядерного синтеза"><input type="hidden" name="short-dest" value="Проект «Железный человек»: реактор холодного ядерного синтеза"><input type="hidden" name="label" value="$order_id"><input type="hidden" name="quickpay-form" value="donate"><input type="hidden" name="targets" value="транзакция {order_id}"><label class="donateOp">Введите сумму</label><input name="sum" value="200" data-type="number"><input type="hidden" name="need-fio" value="true"><input type="hidden" name="need-email" value="true"><input type="hidden" name="need-phone" value="false"><input type="hidden" name="need-address" value="false">
              <label class="donateOp"><input  type="radio" name="paymentType" value="PC">ЮMoney</label>
              <label class="donateOp"><input  type="radio" name="paymentType" value="AC">Банковской картой</label>
              <input class="btn_dn_money" type="submit" value="Перевести"></form>
          </div>
          <p v-if="open" class="description_text" v-html="el.description"></p>
        </div>
        </div>
      </div>
    </div> 
  </div>
  </div>

</template>


<script>
import swiper from '@/components/swiper.vue'
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

export default {
  name:'lesson',
  components: {
    swiper,
  },
  data(){
  	return{
      id:this.$route.query.id || '',
      screenHeight:window.screen.height,
      open:true,
      badRenderComponent:true,
  	}
  },
  mounted(){
    this.GET_DATA_API()
    console.log(this.getLesson)
    this.getLesson
    this.GET_BACKGROUND_API()
    window.addEventListener("orientationchange", this.render, false)


  },
  created(){
  	
  },
  // destroyed() {
  // window.addEventListener("orientationchange", this.render)
  // },
  methods:{
  ...mapMutations({
    
	}),
	...mapActions({
    GET_DATA_API:'GET_DATA_API',
    GET_BACKGROUND_API:'GET_BACKGROUND_API'
	}),
  render(event){
      if(window.screen.height < window.screen.width){
        console.log(window.screen.width)
        this.screenHeight = window.screen.height
        console.log(this.screenHeight)
      } else {
        this.screenHeight = window.screen.height
        console.log(this.screenHeight)
      }
      this.badRenderComponent = !this.badRenderComponent
      this.badRenderComponent = !this.badRenderComponent
    console.log('hello')
  },
  },
  computed:{
  	...mapGetters([
      'GET_API_DATA',
      'GET_BACKGROUND_DATA'
	]),
  getLesson:function(){
    return this.GET_API_DATA.filter(item => item.id == this.id)
  },
  background:function(){
    return this.GET_BACKGROUND_DATA
  },
}
}
</script>

<style scoped>
.donate_box{
  text-align:center;
  margin-top:5%;
}
.donateOp {
    display: block;
    color: #fff;
    font-family: 'Montserrat';
    font-weight: 100;
    margin-top: 10px;
}
.btn_dn_money{
    width: 60%;
    padding: 5px;
    color: #151522;
    font-size: 18px;
    background: #fff;
    outline: none;
    border: 1px solid #CDD2FD;
    border-radius: 5px;
    margin-top: 5%;
    box-shadow: 0px 3px 4px rgb(50 50 71 / 8%);
}
.description_text {
  color: #fff;
  font-size: 17px;
  padding-bottom:70px;
}
.box {
  overflow-y: scroll;
  background-image: linear-gradient(rgba(61, 99, 157, 0.7) 0%, #343D63 100%);
}
::-webkit-scrollbar {
  width: 0;
}
.back{
height:45px;
z-index:1;
top: 0px;
width:100%;
background:initial;
display: flex;
flex-wrap: nowrap;
justify-content: start;
padding-top:20px;
}
.tittle_menu {
    color: #fff;
    text-align: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 20px;
    font-size:17px;
    margin-left: 10px;
    margin-top: 0px;
}
.arrow{
    width: 50px;
    height: 40px;
    margin-left: 15px;
}
.arrow:before{
  content:url('@/assets/ico/arrow.svg');
  width:15px;
  height:15px;
}
.arrow:after{
  content:url('@/assets/ico/arrow.svg');
  width:15px;
  height:15px;
}

.video{
    margin-top: 20px;
    border-radius: 30px;
}
.btn_dn {
    width: 100%;
    padding: 15px;
    color: #151522;
    font-size: 18px;
    background: #fff;
    outline: none;
    border: 1px solid #CDD2FD;
    border-radius: 5px;
    margin-top: 5%;
    box-shadow: 0px 3px 4px rgb(50 50 71 / 8%);
}
.btn_dn:focus {
  outline:none;
}
.marketing_box {
  background:#343D63;
  border-radius:20px;
  text-align:center;
  color:#fff;
  padding:2% 2% 3% 2%;
  margin-top:25px;
}
.text {
  font-size:12px;
  margin-top:7px;
}
.title {
  font-size:20px;
  margin: 10px 0;
}
.link {
  text-transform:uppercase;
  text-decoration:none;
  color:rgba(255,255,255, .7);
  font-size:12px;
}
.btn_dn {

}
.ytp-chrome-top {
  display:none;
}
</style>

