<template>
  <nav>
<!--     <router-link to="/">Main</router-link> |
    <router-link to="/lesson">lessonScreen</router-link> -->
  </nav>
  <router-view/>
</template>
<script>
  export default {
    data(){
      return {
      screenHeight:'',
      }
    },
    // mounted(){
    //   window.addEventListener('resize', function(event) {
    //   if(window.screen.height < window.screen.width){
    //     console.log(window.screen.width)
    //     this.screenHeight = window.screen.width
    //     console.log(this.screenHeight)
    //   } else {
    //     this.screenHeight = window.screen.height
    //     console.log(this.screenHeight)
    //   }
      
    // }, true);
    // }
  }
</script>
<style>
@font-face {
  font-family: 'Montserrat'; 
  src: url(@/assets/fonts/Montserrat-Medium.ttf); 
}

#app {
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height:auto;
}
body {
  margin:0;
  padding:0;
}
html, body {
  height: 100%;
  overflow: hidden;
}
</style>
